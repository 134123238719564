.App {
  text-align: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  opacity: 100%;
  background-image: url('./img/elephant.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.glasses {
  display: grid;
  align-items: center;
  height: 84vh;
  overflow-y: auto;
  padding-top: 1vh;
}
.glass {
  width: 90%;
  height: 130px;
  margin: auto;
  backdrop-filter: blur(10px);
  align-items: center;
  display: grid;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#glass1 {
  background-color: rgba(100, 150, 100, 0.6);
}
#glass2 {
  background-color: rgba(100, 150, 100, 0.5);
}

#glass3 {
  background-color: rgba(100, 150, 100, 0.4);
}

#glass4 {
  background-color: rgba(100, 150, 100, 0.3);
}

#glass5 {
  background-color: rgba(100, 150, 100, 0.2);
}

.upGlass {
  width: 100%;
  height: 65px;
  font-size: large;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(254, 255, 254, 0.54);
  border-bottom: solid 1px rgba(245, 245, 245, 0.1);
}
.downGlass {
  width: 100%;
  height: 65px;
  font-size: xx-large;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
}
.headerText {
  font-size: 60px;
  color: white;
  font-weight: bold;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.menu {
  height: 15vh;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.menuDiv {
  height: 12vh;
  width: 20%;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#menuImg {
  width: 50px;
  padding: 10px;
  border-radius: 50%;
  border: solid grey 1px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.homePage {
  display: grid;
  align-items: center;
  width: 100%;
  height: 85vh;
  padding-top: 1vh;
}
.frens {
  display: grid;
  align-items: center;
  width: 100%;
  height: 85vh;
  padding-top: 1vh;
}
.frensLogo {
  margin: auto;
  width: 90%;
  height: 18vh;
  color: white;
  font-size: x-large;
  font-weight: bold;
}
.frensP {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  float: left;
  width: 48%;
  height: 100%;
  font-size: x-large;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(180, 180, 180)
}
.frensNum {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  float: right;
  width: 48%;
  height: 100%;
  font-size: xxx-large;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frensImage {
  width: 90%;
  height: 29vh;
  margin: auto;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.frensAds {
  width: 90%;
  height: 30vh;
  margin: auto;
  border-radius: 10px;
}
.frensInviteP {
  height: 35%;
  width: 100%;
  background-color: rgba(255, 215, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  border-radius: 10px;
  font-size: large;
}
.frensLink {
  border-radius: 10px;
  height: 55%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  font-size: 10px;
  display: grid;
}
.link {
  width: 100%;
  height: 50%;
}
.btn {
  width: 100%;
  height: 40%;
  margin-bottom: 5%;
}
#copyLink {
  width: 100%;
  height: 6vh;
  border-radius: 10px;
  border: none;
  background-color: rgb(255, 255, 255, 0.2);
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  font-size: large;
}
.homeLogo {
  width: 90%;
  height: 15vh;
  margin: auto;
}
.rightHomeLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 48%;
  height: 15vh;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: xxx-large;
  font-weight: bolder;
  /* Ensure text is centered vertically and horizontally */
  text-align: center;
  position: relative;
}


.rightHomeLogo.flashing {
  
  animation: flash 0.2s steps(2, start) infinite; /* Faster flashing */
}

@keyframes flash {
  50% {
      border-color: black;
  }
  100% {
      border-color: white;
  }
}
.leftHomeLogo {
  float: left;
  width: 48%;
  height: 15vh;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  position: relative;
}

.todaysRolledNumber {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 89%;
  height: 89%;
  border-radius: 10px;
  background-color: rgba(6, 83, 0, 0.7);
  color: rgb(255, 255, 255);
  padding: 10px;
  font-size: xxx-large;
  text-align: center;
}

.fade-out {
  opacity: 1;
  transition: opacity 2s;
}

.fade-out.hidden {
  opacity: 0;
}
.homeAds {
  width: 90%;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-size: medium;
}
.homeAds1 {
  width: 90%;
  height: fit-content;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeAds2 {
  width: 90%;
  height: 7vh;
  background-color: rgba(100, 200, 100, 0.2);
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.homeAds3 {
  width: 90%;
  height: 7vh;
  background-color: rgba(200, 100, 100, 0.2);
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.leftBullish {
  float: left;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: medium;
  text-align: start;
  padding: 20px;
}
.rightBullish {
  margin-left: 5%;
  float: right;
  border-left: solid 1px rgb(132, 132, 132);
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftBearish {
  float: left;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: medium;
  text-align: start;
  padding: 20px;
}
.rightBearish {
  margin-left: 5%;
  float: right;
  border-left: solid 1px rgb(132, 132, 132);
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#upMark {
  height: 30px;
}
#downMark {
  height: 30px;
}
.tradingview-widget-container {
  margin: auto;
}
.tasks {
  align-items: center;
  width: 100%;
  height: 84vh;
  overflow-y: auto;
  padding-top: 2vh;
}
.tasksLogo {
  width: 90%;
  height: 15vh;
  background-color: rgba(0, 0, 0, 0.634);
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: x-large;
  font-weight: bold;
}
.tasksTask {
  width: 90%;
  height: 130px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#frensImg {
  height: 27vh;
}
.loading {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: black;
}
#loadingP {
  margin-top: 20vh;
  font-size: x-large;
  transition: 500ms;
}
#elephantPic {
  width: 100%;
  margin-bottom: 20vh;
}
#taskImage{
height: 130px;
overflow: hidden;
}
#telegramTask {
  background-color: black;
}
#diceImage {
  height: 18vh;
}
#airdropIcon {
  width: 50px;
}
.elefanteTasks {
  width: 90%;
  height: 130px;
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 50, 255, 0.2);
}
.convertPoints {
  width: 90%;
  height: 130px;
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(180, 0, 255, 0.2);
}
.goldenNFT {
  width: 90%;
  height: 130px;
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(251, 255, 0, 0.2);
}
.elefanteAirdrop {
  width: 90%;
  height: 130px;
  backdrop-filter: blur(10px);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 255, 81, 0.2);
}
/* Spinner CSS */
.spinner {
  border: 4px solid #f3f3f37d; /* Light grey */
  border-top: 4px solid #d0f900; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
