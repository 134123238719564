html, body {
  background-color: rgb(33, 33, 33);
  margin: 0;
  font-family:'Courier New', Courier, monospace;
  font-weight: bold;
  color: white;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
